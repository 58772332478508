import TableComponent, { StyledTableRow } from "../../components/Table/Table";
import { tableHeaders } from "./utils";
import { Box, TableCell } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Link } from "@mui/material";
import useDownloadFileCommissionsHistory from "../../hooks/useDownloadFileCommissionsHistory";
import moment from "moment";
import Loading from "../../components/Loading/Loading";

export default function TableCommissionsHistory({ data }: any) {
  const {
    onClick: onClickDownload,
    loading
  } = useDownloadFileCommissionsHistory();

  function downloadFile(filePath: string) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  }

  const handleDownload = async ({ dateIn, dateEnd }: any) => {
    const response = await onClickDownload({
      dateIn: moment(dateIn).toISOString(),
      dateEnd: moment(dateEnd)
        .add(1, "days")
        .toISOString()
    });

    downloadFile(response);
  };

  const sumScribo = data.reduce((accumulator: any, object: any) => {
    return accumulator + object.totalScriboValue;
  }, 0);
  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Loading />
    </Box>
  ) : (
    <TableComponent headerCfg={tableHeaders}>
      {(data as any).map((row: any) => (
        <StyledTableRow key={row.id}>
          <TableCell align="center">
            {row.startDate} / {row.endDate}
          </TableCell>
          <TableCell align="center">
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(row.totalScriboValue)}
          </TableCell>
          <TableCell align="center">
            <Link
              onClick={() =>
                handleDownload({
                  dateIn: row.startDate,
                  dateEnd: row.endDate
                })
              }
              style={{
                cursor: "pointer"
              }}
            >
              <FileDownloadIcon style={{ width: "26px", height: "26px" }} />
            </Link>
          </TableCell>
        </StyledTableRow>
      ))}
      <StyledTableRow>
        <TableCell align="center">
          <b>Total</b>
        </TableCell>
        <TableCell align="center">
          <b>
            {new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR"
            }).format(sumScribo)}
          </b>
        </TableCell>
        <TableCell />
      </StyledTableRow>
    </TableComponent>
  );
}
