import { AppBar, Badge, Box, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ShopIcon from "@mui/icons-material/Shop";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useLocation, useHistory } from "react-router-dom";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import NavListDrawer from "./NavListDrawer";
import {
  Drawer,
  Theme,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuList
} from "@mui/material";

import { useUserState } from "../../state/user";
import { useAuth0 } from "@auth0/auth0-react";
import MenuItemComponent from "./MenuItem";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: "120px"
  },
  logo: {
    width: "155px",
    cursor: "pointer",
    paddingTop: "10px",
    paddingBottom: "10px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "125px",
      textAlign: "initial",
      "& svg": {
        height: "64px"
      }
    }
  }
}));
const withouSidebarRoutes = ["/app/state/disconnected"];

const navLinks = [
  {
    title: "Ecrã inicial",
    path: "/",
    icon: <HomeIcon />
  },
  {
    title: "Compras",
    path: "/cmds/buy",
    icon: <SubscriptionsIcon />
  },
  /* {
    title: "Histórico de Compras - CMD's",
    path: "/cmds/history",
    icon: <PaymentsIcon />
  }, */
  {
    title: "Assinar Prescrições Pendentes",
    fn: () => (window.location.href = "https://sign.myscribo.com/"),
    icon: <BorderColorIcon />
  },
  {
    title: "Conta de Pagamentos",
    path: "/payments-account/resume",
    icon: <ShopIcon />
  }
  /*  {
    title: "Histórico de Comissões",
    path: "/commissions-history",
    icon: <NextWeekIcon />,
  }, */
  /* {
    title: "Videoconsulta",
    path: "/video",
    icon: <VideoCallIcon />
  } */
];

export default function Navbar() {
  const history = useHistory();
  const { user } = useUserState();
  const location = useLocation();
  const { logout } = useAuth0();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navLinksBottom = [
    {
      title: "Terminar Sessão",
      icon: <Logout />,
      fn: () =>
        logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        })
    }
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!user) return <></>;

  if (withouSidebarRoutes.some(item => location.pathname.includes(item)))
    return null;

  return (
    <>
      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="medium"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box style={{ flexGrow: 1 }}>
            <img
              className={classes.logo}
              src="/logo_scribo.png"
              alt="next"
              onClick={() => history.push("/")}
            />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Tooltip title="Abra o menu">
              <IconButton onClick={handleOpenUserMenu} size="large">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Avatar
                      sx={{
                        opacity: 0.85,
                        bgcolor: "primary.contrastText",
                        width: 25,
                        height: 25
                      }}
                    >
                      <DragHandleIcon color="primary" />
                    </Avatar>
                  }
                >
                  <Avatar
                    alt={user?.personalInfo?.givenName}
                    src={user?.personalInfo?.picture}
                    sx={{ width: 75, height: 75 }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "70px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 17,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0
                    }
                  }
                }
              }}
            >
              <MenuList>
                {navLinks.map((item: any) => (
                  <MenuItemComponent
                    key={item.title}
                    onClick={handleCloseUserMenu}
                    item={item}
                  />
                ))}
                <Divider />
                {navLinksBottom.map((item: any) => (
                  <MenuItemComponent
                    key={item.title}
                    onClick={handleCloseUserMenu}
                    item={item}
                  />
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        anchor="left"
        onClose={() => setOpen(false)}
        sx={{ display: { xs: "block", sm: "none" } }}
      >
        <NavListDrawer
          navLinks={navLinks}
          navLinksBottom={navLinksBottom}
          onClose={() => setOpen(false)}
          user={user}
        />
      </Drawer>
    </>
  );
}
